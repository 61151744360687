<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(country_name_en)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.flag"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.flag"
              :text="data.item.country_name_en"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.country_name_en }}
          </b-link>
        </b-media>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/countries',
      addType: 'Add Country',
      addComponentName: 'add-country',
      editComponent: 'edit-country',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: true },
        { key: 'flag_image', label: 'Flag Image' },
        { key: 'name', label: 'Country' },
        { key: 'calling_code', label: 'Calling Code' },
        { key: 'currency_name', label: 'Currency Name' },
        { key: 'currency_code', label: 'Currency Code' },
        { key: 'pound_price', label: 'Pound Price' },
        { key: 'shipping', label: 'Shipping' },
        { key: 'tax', label: 'Tax' },
        { key: 'actions' },
      ],
    }
  },
}
</script>

<style>

</style>
